























































































































import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import DetailHeader from "@/components/Layout/Backoffice/DetailHeader.vue";
import { Role } from "@/models/role.interface";
import { Privilege } from "@/models/privilege.interface";
import { Notification } from "@/models/notification.interface";

@Component({
  components: { Loader, DetailHeader },
})
export default class RoleDetail extends mixins(Navigation, Permissions) {
  loader = false;

  private async created(): Promise<void> {
    this.loader = true;
    await this.$store
      .dispatch("roles/getRoleById", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$t("Roles.fetchError.roleById", {
            name: this.$route.params.id,
          }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    this.setNavigation({
      previousRoute: "/staff/roles",
      icon: "mdi-account-supervisor",
      title: this.role.name,
    });
    this.loader = false;
  }

  private get role(): Role {
    return this.$store.getters["roles/getRole"];
  }

  private get privileges(): Privilege[] {
    let response = this.$store.getters["roles/getRole"].privilege;

    const newFormatedPrivileges: any = [];

    for (let index = 0; index < response.length; index++) {
      let item = newFormatedPrivileges.find((privilege) => {
        return privilege.original_name == response[index].name;
      });

      if (newFormatedPrivileges.length == 0) {
        newFormatedPrivileges.push({
          name: this.readablePrivilege(response[index], true),
          original_name: response[index].name,
          id: response[index].id,
          c: response[index].type == "C" ? true : false,
          r: response[index].type == "R" ? true : false,
          u: response[index].type == "U" ? true : false,
          d: response[index].type == "D" ? true : false,
          count: 1,
        });
      } else if (item !== undefined) {
        switch (response[index].type) {
          case "C":
            item.c = response[index].type == "C" ? true : false;
            break;
          case "R":
            item.r = response[index].type == "R" ? true : false;
            break;
          case "U":
            item.u = response[index].type == "U" ? true : false;
            break;
          case "D":
            item.d = response[index].type == "D" ? true : false;
            break;
          default:
            break;
        }

        item.count++;
      } else {
        newFormatedPrivileges.push({
          name: this.readablePrivilege(response[index], true),
          original_name: response[index].name,
          id: response[index].id,
          c: response[index].type == "C" ? true : false,
          r: response[index].type == "R" ? true : false,
          u: response[index].type == "U" ? true : false,
          d: response[index].type == "D" ? true : false,
          count: 1,
        });
      }
    }

    return newFormatedPrivileges;
  }

  private getElementByName(privilege, name) {
    return privilege.original_name === name;
  }

  private checkPrivilege(id: number) {
    const privilege = (this.role.privilege ? this.role.privilege : []).filter(
      (privilege: Privilege) => {
        return privilege.id == id;
      }
    );
    return privilege.length > 0 ? true : false;
  }
}
