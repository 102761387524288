





















import Component, { mixins } from "vue-class-component";
import { Navigation } from "@/mixins/navigation";

@Component({})
export default class DetailHeader extends mixins(Navigation) {
  private navigatePrevious() {
    this.navigate(this.navigation.previousRoute);
  }

  private get navigation() {
    return this.$store.getters["navigation/getNavigation"];
  }
}
